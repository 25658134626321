import {
    PromptActionButton,
    PromptContent,
    Typography,
} from '@capture/capture-components'
import { _ } from '~/assets/localization/util'

interface RestorePromptProps {
    handleConfirm: () => void
}

export const RestorePrompt = ({ handleConfirm }: RestorePromptProps) => {
    return (
        <PromptContent
            title={_('album_trash__restore_albums_title')}
            actions={[
                <PromptActionButton key="cancel" variant="secondary">
                    {_('cancel')}
                </PromptActionButton>,
                <PromptActionButton
                    variant="primary"
                    key="submit"
                    onPress={handleConfirm}>
                    {_('restore')}
                </PromptActionButton>,
            ]}>
            <Typography.Paragraph>
                {_('album_trash__restore_albums_body')}
            </Typography.Paragraph>
        </PromptContent>
    )
}

import {
    PromptActionButton,
    PromptContent,
    Typography,
} from '@capture/capture-components'
import { _ } from '~/assets/localization/util'

interface DeletePromptProps {
    singular: boolean
    handleConfirm: () => void
}

export const DeletePrompt = ({
    singular,
    handleConfirm,
}: DeletePromptProps) => {
    return (
        <PromptContent
            title={_('album_trash__delete_album_title')}
            actions={[
                <PromptActionButton key="cancel" variant="secondary">
                    {_('cancel')}
                </PromptActionButton>,
                <PromptActionButton
                    variant="destructive"
                    key="submit"
                    onPress={handleConfirm}>
                    {_('delete_')}
                </PromptActionButton>,
            ]}>
            <Typography.Paragraph>
                {singular
                    ? _('album_trash__delete_album_body')
                    : _('album_trash__delete_albums_body')}
            </Typography.Paragraph>
        </PromptContent>
    )
}

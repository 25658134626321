import {
    type CAPBAKUploadPostData,
    CAPBAKUploadPolicy,
} from '@capture/client-api/src/schemas/data-contracts'
import type { Store } from '@reduxjs/toolkit'
import type {
    ChangesAndAsyncUploadStatus,
    FileGroupType,
    JobFilesRequestOptions,
    StoryJobResponse,
} from '~/@types/backend-types'
import { trackEventInternal } from '~/analytics/eventTracking'
import { AlbumDetailsFetched } from '~/state/album/actions'
import { BulkOfActions, type Dispatch } from '~/state/common/actions'
import {
    ConnectedDeviceWasDeleted,
    ConnectedDevicesWasFetched,
    DeleteConnectedDeviceFailed,
    DeleteConnectedDeviceStarted,
    FetchingConnectedDevicesFailed,
    FetchingConnectedDevicesStarted,
    JobSubscriptionsDetected,
    UserSubscribedToAlbum,
} from '~/state/currentUser/actions'
import type { FileMetadata } from '~/state/fileMetadata/actions'
import {
    FileMetadataFetchingFailed,
    FileMetadataFetchingStarted,
    FileMetadataWasFetched,
} from '~/state/fileMetadata/actions'
import * as FilesActions from '~/state/files/actions'
import type { ExtendedJobFile } from '~/state/files/reducer'
import { fileFromFileDescription } from '~/state/files/reducer'
import type { FileWithGrouping } from '~/state/files/selectors'
import { FetchedHostDirectory } from '~/state/hosts/actions'
import type { FileCopiedInfo, FilesFetchedPayload } from '~/state/job/actions'
import {
    AllJobFilesWasFetched,
    FetchedDefaultJob,
    FetchedLastSerialOfDefaultJob,
    FileRangeFetchFailed,
    FileRangeFetchingStarted,
    FileRangeWasFetched,
    FileWasCopiedToJob,
    FileWasRemovedFromJob,
    FilesCopiedToAlbum,
    FilesCopiedToAlbumFailed,
    FilesDeletionFailed,
    FilesDeletionStarted,
    FilesDeletionSucceeded,
    FilesRestorationFailed,
    FilesRestorationStarted,
    FilesRestorationSucceeded,
    JobCopiedToTimeline,
    JobCopiedToTimelineFailed,
    JobPublishingFailed,
    JobWasPublished,
    ShareCreationFailed,
    ShareWasCreated,
    StartFetchingDefaultJob,
    UnableToFetchDefaultJob,
    UnableToFetchJobChanges,
} from '~/state/job/actions'
import {
    type JobInfoReference,
    JobListWasFetched,
} from '~/state/jobInfo/actions'
import { jobFilesToCaptureFiles } from '~/state/recentFiles/recentFilesProcessing'
import {
    RecentFilesStatus,
    addRecentFiles,
    updateRecentsError,
    updateRecentsStatus,
} from '~/state/recentFiles/recentFilesSlice'
import {
    ShareCopiedToTimeline,
    ShareCopiedToTimelineFailed,
} from '~/state/share/actions'
import {
    LongRunningTaskFinished,
    LongRunningTaskStarted,
} from '~/state/statusNotifications/actions'
import { SubscribersWereFetched } from '~/state/subscribers/actions'
import { TimelineMonthsFetched } from '~/state/timeline/actions'
import {
    TrashFileDeleteFailed,
    TrashFileDeleted,
    TrashFilesDeletionFailed,
    TrashFilesDeletionStarted,
    TrashFilesDeletionSucceeded,
    TrashLoadingFailed,
    TrashLoadingStarted,
    TrashLoadingSucceeded,
} from '~/state/trash/actions'
import { FileUploadSucceeded, UploaderFinished } from '~/state/uploader/actions'
import type { FileInformation } from '~/state/uploader/reducer'
import {
    getBackendSucceededFileIDByFileUUID,
    getSucceededFiles,
    isUploaderDone,
} from '~/state/uploader/selectors'
import type {
    UploadDecorator,
    UploadMethod,
} from '~/state/uploader/uploadQueue'
import { UserInfoWasFetched } from '~/state/users/actions'
import { inArray, withoutTheBools } from '~/utilities/arrayUtils'
import { managedPromiseAll } from '~/utilities/promises'
import { sanitisePollHost } from './apiUtils'
import { refreshFileCount, refreshStorageInfo } from './currentUser'
import { getAuthToken, getStoredServiceDict } from './externals'
import { getServiceProvider } from './HostProvider'
import { AppService } from './services/AppService'
import { uploadSyncer } from './syncers/UploadSyncer'
import { BrowserFetchObject, ResponseNotOKError } from './toolbox'

export function fetchDefaultJobID(
    dispatch: Dispatch,
): Promise<JobID | undefined> {
    const hosts = getStoredServiceDict()
    if (hosts === undefined) {
        dispatch(UnableToFetchDefaultJob('StoredServiceDict is undefined'))
        return new Promise(() => undefined)
    }
    dispatch(StartFetchingDefaultJob())
    const service = new AppService(BrowserFetchObject, hosts, getAuthToken())
    return service.getDefaultJob().then(
        (jobInfo) => {
            dispatch(
                FetchedHostDirectory({
                    hosts: {
                        ...hosts,
                        pollHost: sanitisePollHost(hosts.pollHost),
                    },
                }),
            )
            dispatch(FetchedLastSerialOfDefaultJob(jobInfo.last_update))
            dispatch(FetchedDefaultJob(jobInfo.id))
            return jobInfo.id
        },
        (error) => {
            dispatch(UnableToFetchDefaultJob(error))
            return undefined
        },
    )
}

export const fetchTimelineMonths = async (
    dispatch: Dispatch,
    jobID: JobID,
): Promise<void> => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(jobID)
        const { months } = await service.getTimelineMonths(jobID)
        dispatch(TimelineMonthsFetched({ jobID, months }))
    } catch (error) {
        // Do nothing
    }
}

export const initTimeline = async (dispatch: Dispatch, jobID?: JobID) => {
    const timelineID = jobID || (await fetchDefaultJobID(dispatch))
    if (timelineID) {
        await fetchTimelineMonths(dispatch, timelineID)
    }
    return timelineID
}

export const copyAlbumFilesToTimeline = async (
    dispatch: Dispatch,
    albumID: JobID,
    fileIDs: FileID[],
    timelineId?: JobID,
): Promise<void> => {
    try {
        dispatch(LongRunningTaskStarted('filesAreBeingCopied'))

        const service = await getServiceProvider().getAppServiceForJob(albumID)
        await service.copyFilesToDefaultJob(albumID, fileIDs)
        dispatch(FilesActions.FilesCopiedToTimeline(fileIDs))
    } catch (error: any) {
        const reason =
            error.response && error.response.status === 413
                ? 'out_of_storage'
                : 'unknown'
        dispatch(FilesActions.FilesCopiedToTimelineFailed({ reason }))
    }
    dispatch(LongRunningTaskFinished('filesAreBeingCopied'))
    refreshFileCount(dispatch, timelineId)
}

export const copyShareToTimeline = async (
    dispatch: Dispatch,
    jobID: JobID,
): Promise<void> => {
    try {
        dispatch(LongRunningTaskStarted('filesAreBeingCopied'))
        const service = await getServiceProvider().getAppServiceForJob(jobID)
        await service.copyJobToDefaultJob(jobID)
        dispatch(ShareCopiedToTimeline())
    } catch (_error) {
        dispatch(ShareCopiedToTimelineFailed())
    }

    dispatch(LongRunningTaskFinished('filesAreBeingCopied'))
}

export const copyAlbumToTimeline = async (
    dispatch: Dispatch,
    jobID: JobID,
): Promise<void> => {
    try {
        dispatch(LongRunningTaskStarted('filesAreBeingCopied'))
        const service = await getServiceProvider().getAppServiceForJob(jobID)
        await service.copyJobToDefaultJob(jobID)
        dispatch(JobCopiedToTimeline())
    } catch (error: any) {
        const reason =
            error.response && error.response.status === 413
                ? 'out_of_storage'
                : 'unknown'
        dispatch(JobCopiedToTimelineFailed({ reason }))
    }

    dispatch(LongRunningTaskFinished('filesAreBeingCopied'))
}

export const copyMultipleFilesToJob = async (
    dispatch: Dispatch,
    jobID: JobID,
    files: Array<FileWithGrouping<ExtendedJobFile>>,
): Promise<{ succeeded: FileID[]; failed: FileID[]; errors?: Error[] }> => {
    const successFileInfos: FileCopiedInfo[] = []
    const succeeded: FileID[] = []
    const failed: FileID[] = []
    const errors: Error[] = []
    const service = await getServiceProvider().getAppServiceForJob(jobID)

    const associatedFiles: ExtendedJobFile[] = []
    files.forEach((f) => {
        if (f.livePhotoFile) {
            associatedFiles.push(f.livePhotoFile)
        }
        if (f.burstFiles) {
            associatedFiles.push(...f.burstFiles)
        }
    })

    await managedPromiseAll(
        [...files, ...associatedFiles],
        async ({ fileID, path, checksum, mtime, ctime, group }) => {
            const uuid = await service
                .dedupFile(jobID, {
                    path: path.split('/').pop()!, // Extract last segment of path (remove folders, CAPWEB-1172)
                    checksum,
                    mtime,
                    ctime,
                    policy: CAPBAKUploadPolicy.NoDuplicates,
                    group_id: group?.id,
                    group_type: group?.type as FileGroupType,
                    master: group && group.isMaster,
                })
                .catch((e: Error) => {
                    errors.push(e)
                    return undefined
                })
            if (uuid !== undefined) {
                successFileInfos.push({
                    from: fileID,
                    to: { jobID, fileID: uuid },
                })
                if (!group || group?.isMaster) {
                    succeeded.push(fileID)
                }
            } else {
                failed.push(fileID)
            }
        },
    )

    if (successFileInfos.length > 0) {
        dispatch(BulkOfActions(successFileInfos.map(FileWasCopiedToJob)))
    }

    return { succeeded, failed, errors }
}

export const tryCopyFilesToJobCompletely = async (
    dispatch: Dispatch,
    jobID: JobID,
    files: ExtendedJobFile[],
): Promise<FileID[]> => {
    const { succeeded, failed } = await copyMultipleFilesToJob(
        dispatch,
        jobID,
        files,
    )
    if (failed.length > 0) {
        throw Error()
    }

    return succeeded
}

export const copyFilesToAlbum = async (
    dispatch: Dispatch,
    jobID: JobID,
    files: Array<FileWithGrouping<ExtendedJobFile>>,
): Promise<void> => {
    dispatch(LongRunningTaskStarted('filesAreBeingCopied'))

    const { succeeded, failed } = await copyMultipleFilesToJob(
        dispatch,
        jobID,
        files,
    )
    dispatch(
        BulkOfActions(
            withoutTheBools([
                LongRunningTaskFinished('filesAreBeingCopied'),
                succeeded.length > 0 &&
                    FilesCopiedToAlbum({
                        jobID,
                        files: succeeded,
                        showAlbumLink: true,
                    }),
                failed.length > 0 &&
                    FilesCopiedToAlbumFailed({ jobID, files: failed }),
            ]),
        ),
    )
}

const fetchFiles = async (
    jobID: JobID,
    user_uuid: UserID,
    options: JobFilesRequestOptions,
): Promise<FilesFetchedPayload> => {
    const service = await getServiceProvider().getAppServiceForJob(jobID)
    const result = await service.getFiles(jobID, options)
    return {
        jobID,
        lastEvent: result.lastEventSerial,
        files: result.files.map((f) => ({
            ...f,
            jobID,
            fileID: f.id,
            user_uuid,
        })),
    }
}

export const fetchAlbumFiles = async (
    jobID: JobID,
    user_uuid: UserID,
    options: JobFilesRequestOptions,
): Promise<ExtendedJobFile[]> => {
    const result = await fetchFiles(jobID, user_uuid, options)
    const files = result.files.map((file) => fileFromFileDescription(file))
    return files
}

export type FetchFileRangeMethod = (
    dispatch: Dispatch,
    jobID: JobID,
    currentUser: UserID,
    start: DayRef,
    end?: DayRef,
    wantOnlyMasterFiles?: boolean,
) => Promise<void>
export const fetchFileRange: FetchFileRangeMethod = async (
    dispatch,
    jobID,
    currentUser,
    start,
    end,
    wantOnlyMasterFiles,
) => {
    end = end || { ...start, day: 31 }
    try {
        const options = {
            start: `${start.year}/${start.month}/${start.day}`,
            end: `${end.year}/${end.month}/${end.day + 1}`, // Add extra day since backend uses 00:00 as cutoff
        }
        dispatch(FileRangeFetchingStarted({ jobID, start, end }))
        const response = await fetchFiles(jobID, currentUser, options)
        if (wantOnlyMasterFiles) {
            response.files = response.files.filter(
                (fileInfo) => !fileInfo.group_id || fileInfo.master,
            )
        }
        dispatch(FileRangeWasFetched({ ...response, start, end }))
    } catch (error) {
        dispatch(FileRangeFetchFailed({ jobID, start, end }))
    }
}

export const fetchAllUserFiles = async (
    dispatch: Dispatch,
    jobID: JobID,
    currentUser: UserID,
) => {
    try {
        const response = await fetchFiles(jobID, currentUser, { recursive: 1 })
        dispatch(AllJobFilesWasFetched(response))
    } catch (error) {
        // Do nothing
    }
}

export const fetchAllRecentFiles = async (
    userId: UserID,
    dispatch: Dispatch,
) => {
    dispatch(updateRecentsStatus(RecentFilesStatus.FETCHING))
    try {
        const start = new Date().getTime()
        const hosts = getStoredServiceDict()
        if (hosts === undefined) {
            return Promise.reject(new Error('StoredServiceDict is undefined'))
        }
        const authToken = getAuthToken()
        const service = new AppService(BrowserFetchObject, hosts, authToken)
        const timelineID = await service.getDefaultJob()

        const result = await service.getFiles(timelineID.id, {
            recursive: 1,
            uploaded_since: 30,
        })

        const captureFiles = jobFilesToCaptureFiles(
            timelineID.id,
            hosts.thumbHost,
            authToken,
            userId,
            result.files,
        )

        dispatch(
            BulkOfActions([
                addRecentFiles(captureFiles),
                updateRecentsStatus(RecentFilesStatus.SUCCEEDED),
            ]),
        )
        const end = new Date().getTime()
        trackEventInternal('recents_runtime', {
            time: end - start,
            filesCount: captureFiles.length,
        })
    } catch (error) {
        dispatch(updateRecentsStatus(RecentFilesStatus.FAILED))
        if (typeof error === 'string') {
            dispatch(updateRecentsError(error))
        } else if (error instanceof Error) {
            dispatch(updateRecentsError(error.message))
        }
    }
}

export const fetchListOfJobs = (
    dispatch: Dispatch,
    currentUserID: UserID,
    recentlyDeletedAlbums?: string[],
): Promise<void> => {
    return getServiceProvider()
        .getAppServiceForLoggedInUserDefaults()
        .then((service) => service.getJobList())
        .then((jobInfos) =>
            jobInfos.filter((i): i is StoryJobResponse => i.type === 'story'),
        )
        .then(
            (allAlbumInfos) => {
                // Filtering the recently deleted albums to prevent fetching them before they are deleted in backend
                let albumInfos: StoryJobResponse[] = []
                if (recentlyDeletedAlbums && recentlyDeletedAlbums.length > 0) {
                    albumInfos = allAlbumInfos.filter(
                        (info) => !inArray(recentlyDeletedAlbums, info.id),
                    )
                } else {
                    albumInfos = allAlbumInfos
                }

                const jobInfoReferences = albumInfos.map(
                    (info): JobInfoReference => {
                        // Backend provides permissions combined into bitmap when fetching list of jobs
                        const hasPerm = (flag: number) =>
                            (info.permissions & flag) === flag
                        return {
                            job: info.id,
                            info: {
                                type: info.type,
                                ctime: info.ctime,
                                mtime: info.mtime,
                                owner: info.owner.uuid,
                                title: info.name,
                                allow_comments: hasPerm(1),
                                allow_uploads: hasPerm(2),
                                coverPhoto: info.cover_id,
                                isShared: info.privacy_mode === 'shared',
                                last_update: info.last_update,
                                sort_order: info.attributes?.sort_order,
                                size: info.size,
                                has_heic: info.has_heic,
                            },
                        }
                    },
                )

                const detailsFetchedActions = albumInfos.map((info) => {
                    const hasPerm = (flag: number) =>
                        (info.permissions & flag) === flag
                    return AlbumDetailsFetched({
                        albumID: info.id,
                        title: info.name,
                        owner: {
                            userID: info.owner.uuid,
                            name: info.owner.name || '',
                            email: info.owner.email,
                            profilePicture: info.owner.profile_picture_url,
                        },
                        coverPhotoID: info.cover_id,
                        ctime: info.ctime,
                        mtime: info.mtime,
                        allow_comments: hasPerm(1),
                        allow_uploads: hasPerm(2),
                        numberOf: {
                            contributors: info.participant_count,
                            files: info.media_count,
                            comments: info.comment_count,
                            loves: info.reaction_count,
                        },
                        isShared: info.privacy_mode === 'shared',
                        sort_order: info.attributes?.sort_order,
                        size: info.size,
                        hasHEIC: info.has_heic,
                    })
                })
                const jobSubscriptionsAction = JobSubscriptionsDetected(
                    albumInfos
                        .filter(({ owner }) => owner.uuid !== currentUserID)
                        .map(({ id }) => id),
                )

                dispatch(
                    BulkOfActions([
                        JobListWasFetched(jobInfoReferences),
                        ...detailsFetchedActions,
                        jobSubscriptionsAction,
                    ]),
                )
            },
            (error) => {
                dispatch(UnableToFetchDefaultJob(error))
            },
        )
}

export const fetchContributingUsers = (
    dispatch: Dispatch,
    jobID: JobID,
    currentUserID?: string,
): void => {
    getServiceProvider()
        .getAppServiceForJob(jobID)
        .then((ah) => ah.getJobContributors(jobID, true))
        .then((response) => {
            // TODO: refactor subscribers -> participants. Remove subscriptions from current user
            if (
                currentUserID &&
                response.users[currentUserID] &&
                response.users[currentUserID].subscribed === true
            ) {
                dispatch(UserSubscribedToAlbum(jobID))
            }
            const users = Object.keys(response.users).map((uuid) => ({
                userID: uuid,
                name: response.users[uuid].name,
                profilePicture: response.users[uuid].profile_picture_url,
            }))
            dispatch(UserInfoWasFetched(users))
            dispatch(SubscribersWereFetched({ jobID, subscribers: users }))
        })
}

/* Provide a `uploadFile`-method that allows uploads to some other job than timeline to be kept on timeline too */
export const uploaderWithTimelineMirroring =
    (
        store: Store,
        dispatch: Dispatch,
        getDefaultJobID: () => JobID | undefined,
    ): UploadDecorator =>
    (upload: UploadMethod): UploadMethod =>
    async (f: File, i: FileInformation, r?: XMLHttpRequest) => {
        const timelineID = getDefaultJobID()
        const uploadResponse = await upload(f, i, r)
        if (
            uploadResponse.content.uuid &&
            timelineID &&
            i.targetJob !== timelineID
        ) {
            await handleAsyncUploadWithPoller(
                uploadResponse,
                timelineID,
                i.targetJob,
            )
        }

        async function handleAsyncUploadWithPoller(
            response: CAPBAKUploadPostData,
            timelineID: JobID,
            targetID: JobID,
        ) {
            function onBackendUploadSuccess(
                fileID: FileID,
                used_space: number,
            ) {
                const state = store.getState()
                // This FileID is an index id in redux store which is used for Uploader state
                const getBackendSucceededFileID =
                    getBackendSucceededFileIDByFileUUID(state, fileID)

                if (typeof getBackendSucceededFileID === 'number') {
                    dispatch(
                        FileUploadSucceeded({
                            fileID: getBackendSucceededFileID,
                            fileUUID: fileID,
                            usedStorage: used_space,
                        }),
                    )
                    if (isUploaderDone(state)) {
                        dispatch(
                            UploaderFinished({
                                filesCount: getSucceededFiles(state).length,
                            }),
                        )
                    }
                }
            }

            async function copyToTimeline(
                timelineID: JobID,
                targetID: JobID,
                fileID: FileID,
            ) {
                try {
                    const mirrorService =
                        await getServiceProvider().getAppServiceForJob(
                            timelineID,
                        )
                    await mirrorService.copyFilesToDefaultJob(targetID, [
                        fileID,
                    ])
                } catch (error) {
                    // Ignore error from trying to keep the file (this is a hack anyway)
                }
            }

            function errorHandler() {
                store.dispatch(UnableToFetchJobChanges(targetID))
            }

            uploadSyncer.watchFile(
                targetID,
                response.content.uuid,
                (processResponse: ChangesAndAsyncUploadStatus) => {
                    i.alsoTargetTimeline &&
                        copyToTimeline(
                            timelineID,
                            targetID,
                            response.content.uuid,
                        )
                    onBackendUploadSuccess(
                        response.content.uuid,
                        processResponse.info.used_space,
                    )
                },
                errorHandler,
            )
        }
        return uploadResponse
    }

export const uploadFile: UploadMethod = async (
    f: File,
    i: FileInformation,
    r?: XMLHttpRequest,
) => {
    const path = i.targetFolder + '/' + f.name
    const mtime = Math.floor((f.lastModified || Date.now()) / 1000)
    const ah = await getServiceProvider().getAppServiceForJob(i.targetJob)
    return ah.uploadFile(i.targetJob, path, f, mtime, r)
}
export const deleteTrashFile = async (
    dispatch: Dispatch,
    fileID: FileID,
): Promise<void> => {
    dispatch(FilesActions.FileDeletionStarted(fileID))
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.emptyTrashCan(fileID)
        dispatch(TrashFileDeleted(fileID))
    } catch (error) {
        dispatch(TrashFileDeleteFailed(fileID))
    }
}

export const deleteMultipleTrashFiles = async (
    dispatch: Dispatch,
    files: FileID[],
): Promise<void> => {
    try {
        dispatch(TrashFilesDeletionStarted(files))
        dispatch(LongRunningTaskStarted('filesAreBeingDeleted'))
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const successFiles: FileID[] = []
        const failedFiles: FileID[] = []

        await managedPromiseAll(files, async (fileID) => {
            try {
                await service.emptyTrashCan(fileID)
                successFiles.push(fileID)
            } catch (error) {
                failedFiles.push(fileID)
            }
        })

        const actions = withoutTheBools([
            LongRunningTaskFinished('filesAreBeingDeleted'),
            successFiles.length > 0 &&
                TrashFilesDeletionSucceeded(successFiles),
            failedFiles.length > 0 && TrashFilesDeletionFailed(failedFiles),
        ])

        dispatch(BulkOfActions(actions))
    } catch (error) {
        // nothing
    }
}

export const deleteFile = async (
    dispatch: Dispatch,
    jobID: JobID,
    fileID: FileID,
): Promise<void> => {
    try {
        dispatch(FilesActions.FileDeletionStarted(fileID))
        const service = await getServiceProvider().getAppServiceForJob(jobID)
        const resp = await service.deleteFile(jobID, fileID)
        dispatch(
            BulkOfActions([
                FileWasRemovedFromJob({ jobID, fileID }),
                FilesDeletionSucceeded({
                    jobID,
                    files: [fileID],
                    usedSpace: resp ? resp.used_space : undefined,
                }),
            ]),
        )
    } catch (error) {
        const supressRetry = (error as Error)?.message === 'Forbidden'

        dispatch(
            BulkOfActions([
                FilesActions.FileDeletionFailed(fileID),
                FilesDeletionFailed({
                    jobID,
                    files: [fileID],
                    supressRetry,
                }),
            ]),
        )
    }
}

export const deleteMultipleFiles = async (
    dispatch: Dispatch,
    jobID: JobID,
    files: FileID[],
    timelineId?: JobID,
): Promise<void> => {
    try {
        dispatch(FilesDeletionStarted({ jobID, files }))
        dispatch(LongRunningTaskStarted('filesAreBeingDeleted'))

        const service = await getServiceProvider().getAppServiceForJob(jobID)
        const successFiles: FileID[] = []
        const failedFiles: FileID[] = []

        await managedPromiseAll(files, async (fileID) => {
            try {
                await service.deleteFile(jobID, fileID)
                successFiles.push(fileID)
            } catch (error) {
                failedFiles.push(fileID)
            }
        })

        const sortedSuccessFiles = files.filter((fileID) =>
            successFiles.includes(fileID),
        )

        const actions = withoutTheBools([
            ...sortedSuccessFiles.map((fileID) =>
                FileWasRemovedFromJob({ jobID, fileID }),
            ),
            ...failedFiles.map((fileID) =>
                FilesActions.FileDeletionFailed(fileID),
            ),
            LongRunningTaskFinished('filesAreBeingDeleted'),
            sortedSuccessFiles.length > 0 &&
                FilesDeletionSucceeded({ jobID, files: sortedSuccessFiles }),
            failedFiles.length > 0 &&
                FilesDeletionFailed({ jobID, files: failedFiles }),
        ])

        dispatch(BulkOfActions(actions))
        refreshStorageInfo(dispatch)
        refreshFileCount(dispatch, timelineId)
    } catch (error) {
        // error handled by individual deleteFile
    }
}

export const restoreMultipleFiles = async (
    dispatch: Dispatch,
    jobID: JobID,
    files: FileID[],
    timelineId?: JobID,
    associatedFiles?: FileID[],
): Promise<void> => {
    try {
        dispatch(FilesRestorationStarted({ jobID, files }))
        dispatch(LongRunningTaskStarted('filesAreBeingRestored'))
        const service = await getServiceProvider().getAppServiceForJob(jobID)
        const successFiles: FileID[] = []
        const failedFiles: FileID[] = []
        let overQuota = false

        await managedPromiseAll(
            [...files, ...(associatedFiles || [])],
            async (fileID) => {
                try {
                    await service.restoreFile(jobID, fileID)
                    // don't count incidental files
                    if (
                        associatedFiles === undefined ||
                        !associatedFiles.find((fid) => fid === fileID)
                    ) {
                        successFiles.push(fileID)
                    }
                } catch (error) {
                    if (error instanceof ResponseNotOKError) {
                        if (error.response.status === 413) {
                            // REQUEST ENTITY TOO LARGE
                            overQuota = true
                        }
                    }
                    failedFiles.push(fileID)
                }
            },
        )

        const reason = overQuota ? 'out_of_storage' : 'unknown'
        const actions = withoutTheBools([
            LongRunningTaskFinished('filesAreBeingRestored'),
            successFiles.length > 0 &&
                FilesRestorationSucceeded({ jobID, files: successFiles }),
            failedFiles.length > 0 &&
                FilesRestorationFailed({ jobID, files: failedFiles, reason }),
        ])

        dispatch(BulkOfActions(actions))
        refreshStorageInfo(dispatch)
        refreshFileCount(dispatch, timelineId)
    } catch (error) {
        // error handled by individual restoreFile
    }
}

export const fetchFileMetadata = async (
    dispatch: Dispatch,
    jobID: JobID,
    fileID: FileID,
): Promise<void> => {
    dispatch(FileMetadataFetchingStarted(fileID))
    try {
        const service = await getServiceProvider().getAppServiceForJob(jobID)
        const response = await service.getFileMetadata(jobID, fileID)
        const payload: FileMetadata = {
            fileID,
            deviceManufacturer: response.Make,
            deviceModel: response.Model,
            iso: response.ISOSpeedRatings,
            aperture: response.ApertureValue,
            exposure: response.ExposureTime,
            focalLength: response.FocalLength,
        }

        dispatch(FileMetadataWasFetched(payload))
    } catch (error) {
        dispatch(FileMetadataFetchingFailed(fileID))
    }
}

export const fetchConnectedDevices = async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch(FetchingConnectedDevicesStarted())
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const response = await service.getConnectedDevices()

        dispatch(
            ConnectedDevicesWasFetched({
                currentDeviceID: response.current,
                devices: response.devices,
            }),
        )
    } catch (error) {
        dispatch(FetchingConnectedDevicesFailed())
    }
}
export const removeConnectedDevice = async (
    dispatch: Dispatch,
    deviceID: string,
): Promise<void> => {
    dispatch(DeleteConnectedDeviceStarted({ deviceID }))
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.deleteConnectedDevice(deviceID)
        dispatch(ConnectedDeviceWasDeleted({ deviceID }))
    } catch (error) {
        dispatch(DeleteConnectedDeviceFailed({ deviceID }))
    }
}

export const createShareWithFiles = async (
    dispatch: Dispatch,
    name: string,
    files: ExtendedJobFile[],
    password?: string,
): Promise<JobID | undefined> => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const { id } = await service.createJob({
            name,
            public: true,
            password,
        })
        await tryCopyFilesToJobCompletely(dispatch, id, files)
        dispatch(ShareWasCreated(id))
        return id
    } catch (error) {
        dispatch(ShareCreationFailed())
    }
}

export const getSupportedExtensions = async () => {
    const service =
        await getServiceProvider().getAppServiceForLoggedInUserDefaults()

    const extensions = await service.getSupportedExtensions()
    return extensions
}

export const publishJobByEmail = async (
    dispatch: Dispatch,
    jobID: JobID,
    toEmail: string,
    subject: string,
    message?: string,
): Promise<void> => {
    try {
        const service = await getServiceProvider().getAppServiceForJob(jobID)
        await service.publishJob(jobID, {
            to_email: toEmail,
            subject,
            message,
        })
        dispatch(JobWasPublished(jobID))
    } catch (error) {
        dispatch(JobPublishingFailed(jobID))
    }
}

export const loadTrashContent = async (dispatch: Dispatch, offset?: number) => {
    dispatch(TrashLoadingStarted())
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const trashContent = await service.getDeletedFiles({ offset })

        dispatch(TrashLoadingSucceeded(trashContent))
    } catch (error) {
        dispatch(TrashLoadingFailed())
    }
}

// =============== DOWNLOAD UTILITIES

export const getDownloadURL = async (
    jobID: JobID,
    fileID: FileID,
    thumbnail = false,
): Promise<URLstring> => {
    const service = await getServiceProvider().getAppServiceForJob(jobID)
    return service.getFilePreviewURL(jobID, fileID, thumbnail)
}

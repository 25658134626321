import {
    CreditCardUpdateFailed,
    CreditCardUpdated,
    PaymentInfoFetched,
    PlanCancelFailed,
    PlanCanceled,
    PlanChangeFailed,
    PlanChangeStarted,
    PlanChangeSucceeded,
    PlanReactivated,
    PlanReactivationFailed,
    PurchaseFailed,
    PurchaseStarted,
    PurchaseSucceeded,
    StorageProductsFetched,
    UserStoragePlanFetched,
} from '~/state/storagePlan/actions'
import type { Dispatch } from '~/state/common/actions'
import { stripeMode } from '~/config/constants'
import { validateSCA } from './3rdParty/stripe'
import { refreshStorageInfo } from './currentUser'
import { getServiceProvider } from './HostProvider'

export const fetchCreditCardInfo = async (dispatch: Dispatch) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const paymentInfoResp = await service.getStripePaymentMethodInfo()
        dispatch(PaymentInfoFetched(paymentInfoResp))
    } catch (error) {
        // We get 404 Not Found when a user does not have any credit card
        if (error?.toString().includes('Not Found')) {
            dispatch(PaymentInfoFetched(null))
        }
    }
}
export const updateCreditCardInfo = async (
    dispatch: Dispatch,
    paymentTokenID: string,
    cardID: string,
) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.postStripePaymentMethod(paymentTokenID, cardID)
        dispatch(CreditCardUpdated())
    } catch (error) {
        dispatch(CreditCardUpdateFailed())
    }
}

/**
 * Saga for interacting with Stripe and Backend for first-time purchase of storage-plan.
 * SCA complicates flow and introduces tight coupling between flow and backend return type (handling partial payments)
 * TODO?: Replace consumers with StripeForms to obtain access to `stripe`-object in Component for SCA etc there
 */
export const startPlanSubscription = async (
    dispatch: Dispatch,
    planID: string,
    paymentTokenID: string,
    cardID: string,
) => {
    try {
        dispatch(PurchaseStarted())
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const purchaseResponse = await service.stripePurchase({
            plan: planID,
            card: cardID,
            token: paymentTokenID,
        })

        if (purchaseResponse.status === 'incomplete') {
            const sca = await validateSCA(
                purchaseResponse.client_secret,
                cardID,
            )
            const validateResponseCode = await service.validateStripePurchase(
                purchaseResponse.subscription_id,
                sca.id,
            )
            if (validateResponseCode.status !== 200) {
                throw new Error('Validate stripe purchase failed', {
                    cause: {
                        name: validateResponseCode.status.toString(),
                        message: validateResponseCode.statusText,
                    },
                })
            }
        }
        dispatch(PurchaseSucceeded())
        refreshStorageInfo(dispatch)
        getUserStoragePlan(dispatch)
    } catch (error) {
        console.error(error)
        dispatch(PurchaseFailed())
    }
}

const getStorageProducts = async (dispatch: Dispatch) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const resp = await service.getStripeProducts(stripeMode)
        dispatch(StorageProductsFetched(resp))
    } catch (e) {
        /* No harm done */
    }
}

export const getUserStoragePlan = async (dispatch: Dispatch) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const resp = await service.getUserGrants()
        dispatch(UserStoragePlanFetched(resp.result.grants))
    } catch (e) {
        /* No harm done */
    }
}

export const fetchStoragePlanInfo = (dispatch: Dispatch) => {
    return Promise.all([
        getStorageProducts(dispatch),
        getUserStoragePlan(dispatch),
    ])
}

export const cancelStoragePlan = async (dispatch: Dispatch, url: string) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.executeGrantLink(url)
        await getUserStoragePlan(dispatch) // reload user plan
        dispatch(PlanCanceled())
    } catch (e) {
        dispatch(PlanCancelFailed())
    }
}

export const reactivateStoragePlan = async (
    dispatch: Dispatch,
    url: string,
) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.executeGrantLink(url)
        await getUserStoragePlan(dispatch) // reload user plan
        dispatch(PlanReactivated())
    } catch (e) {
        dispatch(PlanReactivationFailed())
    }
}

export const changeCurrentPlan = async (
    dispatch: Dispatch,
    targetPlanID: string,
) => {
    try {
        dispatch(PlanChangeStarted())
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.updateCurrentPlan(targetPlanID)
        await getUserStoragePlan(dispatch) // reload user plan
        await refreshStorageInfo(dispatch)
        dispatch(PlanChangeSucceeded({ newPlanID: targetPlanID }))
    } catch (e) {
        dispatch(PlanChangeFailed())
    }
}

export const deleteCreditCard = async (cardId: string) => {
    const service =
        await getServiceProvider().getAppServiceForLoggedInUserDefaults()
    const response = await service.deleteUserCreditCard(cardId)
    return response
}

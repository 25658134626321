import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkButton } from '@capture/capture-components'
import SvgDownload from '@capture/capture-components/src/icons/Download'
import { _, getStringByAmount } from '~/assets/localization/util'
import type { AlbumJobInfo } from '~/state/albumList/selectors'
import { getCurrentUserUUID } from '~/state/currentUser/selectors'
import { localStorageGet, localStorageSet } from '~/utilities/webStorage'
import { maybePaginatedDownloadJob } from '~/API/download'

interface AlbumDownloadEntryProps {
    album: AlbumJobInfo
}

export const AlbumDownloadEntry = (props: AlbumDownloadEntryProps) => {
    const { album } = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [hasDownloaded, setHasDownloaded] = useState(
        () =>
            localStorageGet(`takeout_album_${album.id}`) ===
            String(album.timestamp.modified),
    )
    const userId = useSelector(getCurrentUserUUID)

    const handleDownloadAlbum = async (
        albumId: string,
        albumName: string,
        albumSize: number,
        lastChange: number,
    ) => {
        setLoading(true)

        if (!userId) {
            setLoading(false)
            return
        }

        localStorageSet(`takeout_album_${albumId}`, String(lastChange))

        await maybePaginatedDownloadJob(dispatch, {
            type: 'takeout',
            jobID: albumId,
            context: 'TakeoutAlbum',
            zipFileName: albumName,
            size: albumSize,
        })
        setHasDownloaded(true)
        setLoading(false)
    }

    const numberOfFiles = album.numberOf?.files ?? 0
    const fileString = getStringByAmount(
        numberOfFiles,
        _('takeout_file'),
        _('takeout_files'),
    )

    return (
        <LinkButton
            icon={SvgDownload}
            isLoading={loading}
            onPress={() =>
                handleDownloadAlbum(
                    album.id,
                    album.title,
                    album.size,
                    album.timestamp.modified,
                )
            }
            isVisited={hasDownloaded}>
            {`${album.title} (${numberOfFiles} ${fileString})`}
        </LinkButton>
    )
}

import type {
    ClientStats,
    UpsertUserAccountAttribute,
    UserStatistics,
} from '~/@types/backend-types'
import { getDeviceProps } from '~/config/constants'
import type { Dispatch } from '~/state/common/actions'
import { BulkOfActions } from '~/state/common/actions'
import {
    AccountAttributesStatus,
    setAccountAttribute,
    setAccountAttributes,
    setAccountAttributesError,
    setAccountAttributesStatus,
} from '~/state/currentUser/accountAttributesSlice'
import {
    FetchStorageDetailsFailed,
    FetchedAlbumCount,
    FetchedFileCount,
    FetchedStorageDetails,
} from '~/state/currentUser/actions'
import {
    ProfileNameChangeFailed,
    ProfileNameChanged,
    ProfilePictureChangeFailed,
    ProfilePictureChangeStarted,
    ProfilePictureChanged,
} from '~/state/users/actions'
import { handleUnknownError } from '~/utilities/errorUtils'
import { getServiceProvider } from './HostProvider'

export const setProfileUserName = async (dispatch: Dispatch, name: string) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.setProfileName(name)
        dispatch(ProfileNameChanged({ name }))
    } catch (e) {
        dispatch(ProfileNameChangeFailed())
    }
}

export const setProfilePicture = async (dispatch: Dispatch, file: Blob) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        dispatch(ProfilePictureChangeStarted())
        await service.setProfilePictureFromBlob(file)
        const { profile_picture_url } = await service.getAccountInfo()
        dispatch(ProfilePictureChanged(profile_picture_url))
    } catch (e) {
        dispatch(ProfilePictureChangeFailed())
    }
}

export const refreshStorageInfo = async (dispatch: Dispatch) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const { max_space, used_space } = await service.getAccountInfo()
        dispatch(
            FetchedStorageDetails({
                used_space: parseInt(used_space, 10),
                max_space: parseInt(max_space, 10),
            }),
        )
    } catch (error) {
        dispatch(FetchStorageDetailsFailed())
    }
}

export const refreshAlbumCount = async (dispatch: Dispatch) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const { number_of_subscribed_albums } = await service.getAccountInfo()
        dispatch(
            FetchedAlbumCount({
                number_of_subscribed_albums: number_of_subscribed_albums,
            }),
        )
    } catch (error) {
        /* empty */
    }
}

export const refreshFileCount = async (
    dispatch: Dispatch,
    timelineId?: JobID,
) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const { file_type_counters } = await service.getAccountInfo()

        // CAPWEB-3121: remove getTimelineMonths call when getAccountInfo response includes `document_count`
        const { months } = await service.getTimelineMonths(
            timelineId ?? (await service.getDefaultJob()).id,
        )

        const documentCount = months.reduce(
            (count, month) => count + month.document_count,
            0,
        )
        const countersWithDocs = {
            ...file_type_counters,
            document_count: documentCount,
        }
        dispatch(
            FetchedFileCount({
                file_type_counters: countersWithDocs,
            }),
        )
    } catch (error) {
        /* empty */
    }
}

export const sendUserStatistics = async (events: UserStatistics[]) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.postUserStatistics(events)
    } catch (e) {
        /* empty */
    }
}

export const sendClientStats = async (data: ClientStats) => {
    try {
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.postClientStats(getDeviceProps().device_id, data)
    } catch (e) {
        /* empty */
    }
}

export const fetchAccountAttributes = async (
    dispatch: Dispatch,
): Promise<void> => {
    try {
        dispatch(setAccountAttributesStatus(AccountAttributesStatus.FETCHING))
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const response = await service.getAccountAttributes()
        dispatch(
            BulkOfActions([
                setAccountAttributes(response.result),
                setAccountAttributesStatus(AccountAttributesStatus.SUCCEEDED),
            ]),
        )
    } catch (error) {
        let errorMessage = 'unknown Error'
        if (typeof error === 'string') {
            errorMessage = error
        } else if (error instanceof Error) {
            errorMessage = error.message
        }
        dispatch(
            BulkOfActions([
                dispatch(
                    setAccountAttributesStatus(AccountAttributesStatus.FAILED),
                ),
                dispatch(setAccountAttributesError(errorMessage)),
            ]),
        )
    }
}

export const upsertAccountAttributes = async (
    options: UpsertUserAccountAttribute,
    dispatch: Dispatch,
) => {
    try {
        dispatch(setAccountAttributesStatus(AccountAttributesStatus.FETCHING))
        const service =
            await getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const response = await service.upsertAccountAttributes(options)
        dispatch(
            BulkOfActions([
                setAccountAttribute(response.result[0]),
                setAccountAttributesStatus(AccountAttributesStatus.SUCCEEDED),
            ]),
        )
    } catch (error) {
        const errorMessage = handleUnknownError(error)
        dispatch(
            BulkOfActions([
                dispatch(
                    setAccountAttributesStatus(AccountAttributesStatus.FAILED),
                ),
                dispatch(setAccountAttributesError(errorMessage)),
            ]),
        )
    }
}
